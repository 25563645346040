*,
::before,
::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html {
  -moz-tab-size: 4;
  -o-tab-size: 4;
     tab-size: 4;
}

html {
  line-height: 1.15;
  
  -webkit-text-size-adjust: 100%;
  
}

body {
  margin: 0;
}

body {
  font-family:
		system-ui,
		-apple-system, 
		'Segoe UI',
		Roboto,
		Helvetica,
		Arial,
		sans-serif,
		'Apple Color Emoji',
		'Segoe UI Emoji';
}

b {
  font-weight: bolder;
}

code {
  font-family:
		ui-monospace,
		SFMono-Regular,
		Consolas,
		'Liberation Mono',
		Menlo,
		monospace;
  
  font-size: 1em;
  
}

button,
input,
select,
textarea {
  font-family: inherit;
  
  font-size: 100%;
  
  line-height: 1.15;
  
  margin: 0;
  
}

button,
select {
  
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  
  outline-offset: -2px;
  
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  
  font: inherit;
  
}

h3,
h5,
p {
  margin: 0;
}

button {
  background-color: transparent;
  background-image: none;
}

html {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  
  line-height: 1.5;
  
}

body {
  font-family: inherit;
  line-height: inherit;
}

*,
::before,
::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  
  border-width: 0;
  
  border-style: solid;
  
  border-color: currentColor;
  
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button,
[role="button"] {
  cursor: pointer;
}

:-moz-focusring {
  outline: auto;
}

h3,
h5 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button,
input,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

code {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

img,
svg,
iframe,
object {
  display: block;
  
  vertical-align: middle;
  
}

img {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, ::before, ::after {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.gn-pointer-events-none {
  pointer-events: none !important;
}

.gn-fixed {
  position: fixed !important;
}

.gn-absolute {
  position: absolute !important;
}

.gn-relative {
  position: relative !important;
}

.gn-inset-0 {
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
}

.gn-top-0 {
  top: 0px !important;
}

.gn-right-4 {
  right: 1rem !important;
}

.gn-left-0 {
  left: 0px !important;
}

.gn-z-10 {
  z-index: 10 !important;
}

.gn-m-0 {
  margin: 0px !important;
}

.gn-mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.gn-mx-8 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.gn-my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.gn-mt-2 {
  margin-top: 0.5rem !important;
}

.gn-mt-4 {
  margin-top: 1rem !important;
}

.gn-mt-36 {
  margin-top: 9rem !important;
}

.gn-mr-1 {
  margin-right: 0.25rem !important;
}

.gn-mr-2 {
  margin-right: 0.5rem !important;
}

.gn-mr-4 {
  margin-right: 1rem !important;
}

.gn-mr-5 {
  margin-right: 1.25rem !important;
}

.gn-mb-1 {
  margin-bottom: 0.25rem !important;
}

.gn-mb-2 {
  margin-bottom: 0.5rem !important;
}

.gn-mb-3 {
  margin-bottom: 0.75rem !important;
}

.gn-mb-4 {
  margin-bottom: 1rem !important;
}

.gn-mb-5 {
  margin-bottom: 1.25rem !important;
}

.gn-mb-6 {
  margin-bottom: 1.5rem !important;
}

.gn-ml-1 {
  margin-left: 0.25rem !important;
}

.gn-ml-2 {
  margin-left: 0.5rem !important;
}

.gn-ml-4 {
  margin-left: 1rem !important;
}

.gn-block {
  display: block !important;
}

.gn-flex {
  display: -webkit-box !important;
  display: flex !important;
}

.gn-grid {
  display: grid !important;
}

.gn-hidden {
  display: none !important;
}

.gn-h-4 {
  height: 1rem !important;
}

.gn-h-5 {
  height: 1.25rem !important;
}

.gn-h-8 {
  height: 2rem !important;
}

.gn-h-10 {
  height: 2.5rem !important;
}

.gn-h-12 {
  height: 3rem !important;
}

.gn-h-16 {
  height: 4rem !important;
}

.gn-h-24 {
  height: 6rem !important;
}

.gn-h-full {
  height: 100% !important;
}

.gn-w-4 {
  width: 1rem !important;
}

.gn-w-5 {
  width: 1.25rem !important;
}

.gn-w-8 {
  width: 2rem !important;
}

.gn-w-10 {
  width: 2.5rem !important;
}

.gn-w-16 {
  width: 4rem !important;
}

.gn-w-20 {
  width: 5rem !important;
}

.gn-w-24 {
  width: 6rem !important;
}

.gn-w-40 {
  width: 10rem !important;
}

.gn-w-48 {
  width: 12rem !important;
}

.gn-w-1\/2 {
  width: 50% !important;
}

.gn-w-full {
  width: 100% !important;
}

.gn-flex-1 {
  -webkit-box-flex: 1 !important;
          flex: 1 1 0% !important;
}

.gn-flex-auto {
  -webkit-box-flex: 1 !important;
          flex: 1 1 auto !important;
}

.gn-flex-none {
  -webkit-box-flex: 0 !important;
          flex: none !important;
}

@-webkit-keyframes gn-spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes gn-spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes gn-ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes gn-pulse {
  50% {
    opacity: .5;
  }
}

@keyframes gn-bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.gn-cursor-pointer {
  cursor: pointer !important;
}

.gn-flex-col {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
          flex-direction: column !important;
}

.gn-place-items-end {
  place-items: end !important;
}

.gn-items-start {
  -webkit-box-align: start !important;
          align-items: flex-start !important;
}

.gn-items-end {
  -webkit-box-align: end !important;
          align-items: flex-end !important;
}

.gn-items-center {
  -webkit-box-align: center !important;
          align-items: center !important;
}

.gn-justify-end {
  -webkit-box-pack: end !important;
          justify-content: flex-end !important;
}

.gn-justify-center {
  -webkit-box-pack: center !important;
          justify-content: center !important;
}

.gn-justify-between {
  -webkit-box-pack: justify !important;
          justify-content: space-between !important;
}

.gn-self-center {
  align-self: center !important;
}

.gn-overflow-auto {
  overflow: auto !important;
}

.gn-overflow-hidden {
  overflow: hidden !important;
}

.gn-overflow-scroll {
  overflow: scroll !important;
}

.gn-rounded-none {
  border-radius: 0px !important;
}

.gn-rounded {
  border-radius: 0.25rem !important;
}

.gn-rounded-lg {
  border-radius: 0.5rem !important;
}

.gn-rounded-full {
  border-radius: 9999px !important;
}

.gn-border-0 {
  border-width: 0px !important;
}

.gn-border-2 {
  border-width: 2px !important;
}

.gn-border {
  border-width: 1px !important;
}

.gn-border-t-2 {
  border-top-width: 2px !important;
}

.gn-border-b {
  border-bottom-width: 1px !important;
}

.gn-border-white {
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
}

.gn-border-gray-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
}

.gn-border-red-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
}

.gn-bg-overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.gn-bg-main {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(204, 0, 0, var(--tw-bg-opacity)) !important;
}

.gn-bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.gn-bg-gray-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
}

.gn-bg-gray-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
}

.gn-bg-gray-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
}

.gn-bg-gray-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
}

.gn-bg-red-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
}

.gn-bg-red-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
}

.gn-bg-green-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
}

.gn-bg-blue-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
}

.hover\:gn-bg-gray-400:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
}

.hover\:gn-bg-red-700:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
}

.gn-bg-contain {
  background-size: contain !important;
}

.gn-bg-no-repeat {
  background-repeat: no-repeat !important;
}

.gn-object-contain {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

.gn-object-cover {
  -o-object-fit: cover !important;
     object-fit: cover !important;
}

.gn-object-left-top {
  -o-object-position: left top !important;
     object-position: left top !important;
}

.gn-p-1 {
  padding: 0.25rem !important;
}

.gn-p-2 {
  padding: 0.5rem !important;
}

.gn-p-3 {
  padding: 0.75rem !important;
}

.gn-p-5 {
  padding: 1.25rem !important;
}

.gn-p-8 {
  padding: 2rem !important;
}

.gn-px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.gn-px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.gn-px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.gn-px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.gn-px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.gn-py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.gn-py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.gn-pt-4 {
  padding-top: 1rem !important;
}

.gn-pr-1 {
  padding-right: 0.25rem !important;
}

.gn-pb-5 {
  padding-bottom: 1.25rem !important;
}

.gn-pl-1 {
  padding-left: 0.25rem !important;
}

.gn-pl-5 {
  padding-left: 1.25rem !important;
}

.gn-text-center {
  text-align: center !important;
}

.gn-text-right {
  text-align: right !important;
}

.gn-text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.gn-text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.gn-text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.gn-text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.gn-text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.gn-font-medium {
  font-weight: 500 !important;
}

.gn-font-semibold {
  font-weight: 600 !important;
}

.gn-font-bold {
  font-weight: 700 !important;
}

.gn-uppercase {
  text-transform: uppercase !important;
}

.gn-leading-none {
  line-height: 1 !important;
}

.gn-text-black {
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
}

.gn-text-white {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.gn-text-gray-400 {
  --tw-text-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
}

.gn-text-gray-500 {
  --tw-text-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
}

.gn-text-gray-600 {
  --tw-text-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
}

.gn-text-gray-700 {
  --tw-text-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
}

.gn-text-red-500 {
  --tw-text-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
}

.hover\:gn-text-white:hover {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.gn-underline {
  text-decoration: underline !important;
}

.gn-line-through {
  text-decoration: line-through !important;
}

.gn-opacity-50 {
  opacity: 0.5 !important;
}

.gn-opacity-100 {
  opacity: 1 !important;
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000;
}

.gn-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

input {
  font-weight: 400 !important;
}

p {
  margin: 0px;
  font-size: 1rem;
  line-height: 1.5rem;
}

.input {
  border-radius: 0.25rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.ng-invalid.ng-touched {
  --tw-border-opacity: 1 !important;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
}

.presets-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px 20px;
}

.presets-grid>*{
  margin-bottom:60px;
}

@media (max-width: 500px) {
  .presets-grid {
    display: block;
    max-width:300px;
    margin:auto;
  }
}

@media (min-width: 768px) {
  .presets-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  .presets-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 640px) {

  .sm\:gn-right-0 {
    right: 0px !important;
  }

  .sm\:gn-flex {
    display: -webkit-box !important;
    display: flex !important;
  }

  .sm\:gn-grid {
    display: grid !important;
  }

  .sm\:gn-h-20 {
    height: 5rem !important;
  }

  .sm\:gn-w-16 {
    width: 4rem !important;
  }

  .sm\:gn-w-20 {
    width: 5rem !important;
  }

  .sm\:gn-w-24 {
    width: 6rem !important;
  }

  .sm\:gn-w-full {
    width: 100% !important;
  }

  .sm\:gn-px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}

@media (min-width: 768px) {

  .md\:gn-mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .md\:gn-mx-36 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .md\:gn-mt-36 {
    margin-top: 9rem !important;
  }

  .md\:gn-mr-2 {
    margin-right: 0.5rem !important;
  }

  .md\:gn-mb-0 {
    margin-bottom: 0px !important;
  }

  .md\:gn-ml-2 {
    margin-left: 0.5rem !important;
  }

  .md\:gn-flex {
    display: -webkit-box !important;
    display: flex !important;
  }

  .md\:gn-w-8 {
    width: 2rem !important;
  }

  .md\:gn-w-40 {
    width: 10rem !important;
  }

  .md\:gn-w-auto {
    width: auto !important;
  }

  .md\:gn-w-1\/2 {
    width: 50% !important;
  }

  .md\:gn-flex-1 {
    -webkit-box-flex: 1 !important;
            flex: 1 1 0% !important;
  }

  .md\:gn-flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }
}

@media (min-width: 1024px) {

  .lg\:gn-w-28 {
    width: 7rem !important;
  }

  .lg\:gn-px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
}