\:root
    --color-main: #4299e1
    --color-main-hover: #2c5282

    font-size: 16px

@import 'ngx-toastr/toastr'
@import '~@ng-select/ng-select/themes/default.theme.css'


@import '../node_modules/swiper/swiper-bundle.css'


.gn-voucher-imports
    /*@import '~@ng-select/ng-select/scss/default.theme.scss'*/
    @import 'tailwind.scss'

geronigo-vouchers, basket-modal, rezbot-vouchers
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"

    fa-icon
        display: inline-block

    *
        backface-visibility: visible
        background: 0
        border: none
        box-shadow: none
        caption-side: top
        clear: none
        float: none
        font-style: inherit
        font-variant: inherit
        font-weight: inherit
        font-size: inherit
        text-transform: inherit
        height: auto
        letter-spacing: normal
        line-height: normal
        list-style: none
        max-height: none
        max-width: none
        min-height: auto
        min-width: auto
        opacity: 1
        overflow: visible
        position: static
        text-align: inherit
        text-align-last: auto
        text-decoration: none
        text-decoration-color: inherit
        text-indent: 0
        text-shadow: none
        transform: none
        transition: none
        vertical-align: baseline
        visibility: visible
        white-space: normal
        width: auto
        word-spacing: normal
        z-index: auto

        box-sizing: border-box !important
        margin: 0
        padding: 0
        background-color: transparent

    p
        color: #4a5568

    button
        .gn-hint
            display: none

        &:disabled
            &:hover
                & > .gn-hint
                    display: block

                & > .gn-text
                    display: none

    .gn-spinner-loader
        margin: 0 5px
        text-align: center

        & > div
            width: 5px
            height: 5px
            background-color: #2d3748
            vertical-align: middle
            border-radius: 100%
            display: inline-block
            animation: sk-bouncedelay 1.4s infinite ease-in-out both

        &.gn-spinner-inverse > div
            background-color: #ffffff

        .gn-bounce2, .gn-bounce3
            margin-left: 2px

        & .gn-bounce1
            animation-delay: -0.32s

        & .gn-bounce2
            animation-delay: -0.16s

    .gn-spin
        animation-name: spin
        animation-duration: 1000ms
        animation-iteration-count: infinite
        animation-timing-function: linear

    .gn-outline
        text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000 !important

    .gn-sale
        border: 30px solid #cc0000
        border-bottom-color: transparent
        border-right-color: transparent

        span
            top: -17px
            left: -30px
            transform: rotate(-45deg)

    @keyframes sk-bouncedelay
        0%, 80%, 100%
            transform: scale(0)
        40%
            transform: scale(1.0)

    @keyframes spin
        from
            transform: rotate(0deg)
        to
            transform: rotate(360deg)

    .gn-bg-main
        background: #2b6cb0
        background: var(--color-main)

        &:hover
            background: #2c5282
            background: var(--color-main-hover)
    
    .voucher-add-hint, .voucher-add-intro
        color:#ccc
    .voucher-cart
        color:#fff
    .presets-grid, .voucher-presets, .voucher-presets p
        color:#aaa
    .confirm_overlay
        background-color:#fff

    .dark
        .voucher-add-hint, .voucher-add-intro, .voucher-cart, .presets-grid, .voucher-presets, .voucher-presets p
            color:#ccc 
        .voucher-add form input
            background-color:#000
            color:#fff
        .confirm_overlay
            background-color:#000
    
    .light
        .voucher-add-hint, .voucher-add-intro, .voucher-cart.presets-grid, .voucher-presets, .voucher-presets p
            color:#333
        .voucher-add form input
            background-color:#fff
            color:#000
        .confirm_overlay
            background-color:#fff